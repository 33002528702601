import {useState} from "react";
import useLayoutStore from "../stores/layout";

export const useLayout = () => {
    const [headerPosition, setHeaderPosition] = useState('top');

    const menuOpen = useLayoutStore((state: any) => state.menuOpen);
    const setMenuOpen = useLayoutStore((state: any) => state.setMenuOpen);

    const hasOverlay = useLayoutStore((state: any) => state.hasOverlay);
    const setHasOverlay = useLayoutStore((state: any) => state.setHasOverlay);

    const hasTransitionMask = useLayoutStore((state: any) => state.hasTransitionMask);
    const setHasTransitionMask = useLayoutStore((state: any) => state.setHasTransitionMask);

    const hasOffsetTop = useLayoutStore((state: any) => state.hasOffsetTop);
    const setHasOffsetTop = useLayoutStore((state: any) => state.setHasOffsetTop);

    return {
        headerPosition,
        setHeaderPosition,
        menuOpen,
        setMenuOpen,
        hasOverlay,
        setHasOverlay,
        hasTransitionMask,
        setHasTransitionMask,
        hasOffsetTop,
        setHasOffsetTop,
    }
}