import {useEffect, useState} from "react";

interface SeoProps {
    title?: string;
    description?: string;
    image?: string;
    keywords?: string[];
}

export const useSeo = () => {
    const [meta, setMeta] = useState({
        title: "",
        description: "",
        image: "",
        keywords: []
    } as SeoProps);

    const createMissingTags = () => {
        if (!document.querySelector('meta[name="description"]')) {
            const meta = document.createElement('meta');
            meta.name = "description";
            meta.content = "";
            document.getElementsByTagName('head')[0].appendChild(meta);
        }

        if (!document.querySelector('meta[name="keywords"]')) {
            const meta = document.createElement('meta');
            meta.name = "keywords";
            meta.content = "";
            document.getElementsByTagName('head')[0].appendChild(meta);
        }

        if (!document.querySelector('meta[property="og:title"]')) {
            const meta = document.createElement('meta');
            meta.name = "og:title";
            meta.content = "";
            document.getElementsByTagName('head')[0].appendChild(meta);
        }

        if (!document.querySelector('meta[property="og:description"]')) {
            const meta = document.createElement('meta');
            meta.name = "og:description";
            meta.content = "";
            document.getElementsByTagName('head')[0].appendChild(meta);
        }

        if (!document.querySelector('meta[property="og:image"]')) {
            const meta = document.createElement('meta');
            meta.name = "og:image";
            meta.content = "";
            document.getElementsByTagName('head')[0].appendChild(meta);
        }

        if (!document.querySelector('meta[property="og:url"]')) {
            const meta = document.createElement('meta');
            meta.name = "og:url";
            meta.content = "";
            document.getElementsByTagName('head')[0].appendChild(meta);
        }

        if (!document.querySelector('meta[name="twitter:title"]')) {
            const meta = document.createElement('meta');
            meta.name = "twitter:title";
            meta.content = "";
            document.getElementsByTagName('head')[0].appendChild(meta);
        }

        if (!document.querySelector('meta[name="twitter:description"]')) {
            const meta = document.createElement('meta');
            meta.name = "twitter:description";
            meta.content = "";
            document.getElementsByTagName('head')[0].appendChild(meta);
        }

        if (!document.querySelector('meta[name="twitter:image"]')) {
            const meta = document.createElement('meta');
            meta.name = "twitter:image";
            meta.content = "";
            document.getElementsByTagName('head')[0].appendChild(meta);
        }
    }

    useEffect(() => {
        createMissingTags();

        document.title = meta.title ?? '';

        // Change meta tags
        document.querySelector('meta[name="description"]')?.setAttribute("content", meta.description ?? '');
        document.querySelector('meta[name="keywords"]')?.setAttribute("content", (meta.keywords ?? []).join(", "));

        // Change Open Graph meta tags
        document.querySelector('meta[property="og:title"]')?.setAttribute("content", meta.title ?? '');
        document.querySelector('meta[property="og:description"]')?.setAttribute("content", meta.description ?? '');
        document.querySelector('meta[property="og:image"]')?.setAttribute("content", meta.image ?? '');
        document.querySelector('meta[property="og:url"]')?.setAttribute("content", window.location.href);

        // Change Twitter meta tags
        document.querySelector('meta[name="twitter:title"]')?.setAttribute("content", meta.title ?? '');
        document.querySelector('meta[name="twitter:description"]')?.setAttribute("content", meta.description ?? '');
        document.querySelector('meta[name="twitter:image"]')?.setAttribute("content", meta.image ?? '');
    }, [meta]);

    return {meta, setMeta};
}