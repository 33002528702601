import {createBrowserRouter} from "react-router-dom";
import Layout from "./layout/Default";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import React from "react";

import Privacy from "./pages/legal/Privacy";

import E404 from "./errors/E404";

const router = createBrowserRouter([
    {
        path: "/:lang?",
        element: <Layout/>,
        children: [
            {
                path: "",
                element: <Home/>,
            },
            // {
            //     path: "work",
            //     element: <Work/>,
            // },
            // {
            //     path: "work/:slug/:subSlug?",
            //     element: <WorkItem/>,
            // },
            // {
            //     path: "solutions",
            //     element: <Solutions/>,
            // },
            // {
            //     path: "solutions/:slug/:subSlug?",
            //     element: <SolutionItem/>,
            // },
            {
                path: "contact",
                element: <Contact/>
            },
            {
                path: "*",
                element: <E404/>
            }
        ]
    },
    {
        path: "legal/privacy",
        element: <Privacy/>
    }
]);

// Scroll to top on route change
router.subscribe(() => {
    window.scrollTo(0, 0);
});

export default router;