import styles from './MenuButton.module.scss';

interface MenuButtonProps {
    onClick?: any;
    open?: boolean
}
 
const MenuButton = ({...props}: MenuButtonProps) => {
    const classNames = [styles['menu-button']];
    if (props.open) {
        classNames.push(styles['menu-button--open']);
    }
    return (
        <button className={classNames.join(' ')} {...props} >
            <span>Menu</span>
        </button>
    )
};

export default MenuButton;