import Container from "../components/containers/Container/Container";
import Hero from "../components/elements/Hero";
import ScrollDown from "../components/elements/ScrollDown";
import Typo from "../components/containers/Typo/Typo";
import {useSeo} from "../hooks/seo";
import {useEffect, useMemo, useState} from "react";
import StickyContainer from "../components/containers/Container/StickyContainer";
import Activities from "../components/elements/Activities";
import Clients from "../components/elements/Clients";

const Home = () => {
    const {setMeta} = useSeo();

    const heroText = [
        "Making good sh*t",
        "in web development",
        "since 2013."
    ];

    useEffect(() => {
        setMeta({
            title: "Bérenger Stasse — Web developer",
            description: "I am a full-stack web and applications developer with a no-bullshit mind.",
        });
    }, [setMeta]);

    const [modemSoundPlaying, setModemSoundPlaying] = useState(false);
    const playModemSound = (e: any) => {
        e.preventDefault();

        if (modemSoundPlaying) return;

        setModemSoundPlaying(true);

        const audio = require("../assets/sounds/modem.mp3");
        const modem = new Audio(audio);

        modem.play().then(r => r);
        modem.addEventListener('ended', () => {
            setModemSoundPlaying(false);
        });
    }

    const scrollTo = useMemo(() => {
        return window.innerHeight*.75
    }, []);

    return (<>
            <Container size="xl" fullHeight={true} justify="center">
                <Hero lines={heroText}/>
            </Container>

            <Activities/>

            <Clients />

            <StickyContainer heightRatio={2} appearSpans={true}>
                <Container size="xl" orientation="vertical" fullHeight={true}
                           justify="center">
                    <Typo tag="div" variant="headline" size="fit" fitRatio={.9}>
                        <p data-particles={true}>
                            <span> I</span>
                            <span> am</span>
                            <span> a</span>
                            <strong>
                                <span> 90s </span>
                                <span>child</span>
                            </strong>
                            <span>.</span>
                            <span> I</span>
                            <span> grew</span>
                            <span> up</span>
                            <span> in</span>
                            <span> the</span>
                            <span> Belgian</span>
                            <span> countryside</span>
                            <span> on</span>
                            <span> a</span>
                            <br/>
                            <a href="#" onClick={playModemSound}>
                                <span>     56k-modem</span>
                                <span>     soundtrack.</span>
                            </a>
                        </p>
                    </Typo>

                    <Typo tag="div" variant="headline" size="fit">
                        <p>
                            <span> As</span>
                            <span> a</span>
                            <strong>
                                <span> full-stack</span>
                            </strong>
                            <span> developer</span>
                            <span> with</span>
                            <span> a</span>
                            <strong>
                                <span> no-bullshit</span>
                            </strong><span> mind</span>
                            <span>,</span>
                            <span> I</span>
                            <span> help</span>
                            <span> companies</span>
                            <span> and</span>
                            <span> individuals</span>
                            <span> to</span>
                            <strong>
                                <span> level</span>
                                <span> up</span>
                            </strong>
                            <span>   their</span>
                            <span>   digital</span>
                            <span>   presence.</span>
                        </p>
                    </Typo>
                </Container>
            </StickyContainer>

            <ScrollDown to={scrollTo}/>
        </>
    )
}

export default Home;