import styles from "./Hero.module.scss";
import {CSSProperties} from "react";

interface HeroProps {
    lines: string[];
    last_line_offset?: boolean
}

const Hero = ({lines, last_line_offset}: HeroProps) => {
    const lines_count = lines.length;
    let words_count = 0;

    const classNames = [styles.hero];
    if (lines_count === 1) {
        classNames.push(styles['hero--one-line']);
    }

    if (last_line_offset === undefined || last_line_offset) {
        classNames.push(styles['hero--last-line-offset']);
    }

    return (
        <h1 className={classNames.join(' ')}>
            {lines.map((line, line_index) => (
                <span key={line_index} className={styles.hero__line}>
                    {line.split(' ').map((word, word_index) => {
                        words_count += 1;

                        return (
                            <span key={word_index} className={styles.hero__word}
                                  style={({
                                      '--pos': words_count.toString()
                                  } as CSSProperties)}>
                           {word}
                        </span>)
                    })}
                </span>
            ))}
        </h1>
    )
}

export default Hero