import {CSSProperties, useEffect, useRef} from "react";
import styles from "./Container.module.scss";
import {useScreen} from "../../../hooks/screen";
import Container, {ContainerProps} from "./Container";

interface StickyContainerProps extends ContainerProps {
    heightRatio?: number;
    appearSpans?: boolean;
}

const StickyContainer = ({heightRatio, appearSpans, children, ...rest}: StickyContainerProps) => {
    const {scrolledPixels} = useScreen();
    const element = useRef<HTMLDivElement>(null);

    const classNames = [styles['sticky-container']];

    if (appearSpans) {
        classNames.push(styles['sticky-container--appear-spans']);
    }

    useEffect(() => {
        const element_css_top = parseInt(window.getComputedStyle(element.current as Element).top);
        const element_top_position = element.current?.getBoundingClientRect().top || 0;
        const viewport_height = window.innerHeight;
        const element_height = viewport_height * (heightRatio || 1); //  (element.current?.getBoundingClientRect().height || 0);

        const scrolled_in_element = (element_top_position - element_css_top + scrolledPixels - viewport_height);

        const scrolled_in_element_percentage = Math.min(100, Math.max(0, scrolled_in_element / (element_height - viewport_height) * 100));

        if (appearSpans) {
            const count_spans = element.current?.querySelectorAll('span').length || 0;
            if (count_spans)
                element.current?.querySelectorAll('span').forEach((span, index) => {
                    if (index < count_spans * scrolled_in_element_percentage / 100) {
                        span.classList.add(styles['sticky-container--appear-span']);
                    } else {
                        span.classList.remove(styles['sticky-container--appear-span']);
                    }
                })
        }
    }, [scrolledPixels, appearSpans, element, heightRatio]);

    return (
        <div className={classNames.join(' ')} style={{
            '--height-ratio': (heightRatio ?? 1)
        } as CSSProperties}>
            <div className={styles['sticky-container__inner']} ref={element}>
                <Container {...rest} children={children}/>
            </div>
        </div>
    );
}

export default StickyContainer;