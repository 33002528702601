'use client'

import * as THREE from "three";

const easeOutSine = (t, b, c, d) => {
    return c * Math.sin((t / d) * (Math.PI / 2)) + b;
};

const easeOutExpo = (t, b, c, d) => {
    return c * (1 - Math.pow(2, -10 * t / d)) + b;
}

const easeOutQuad = (t, b, c, d) => {
    t /= d;
    return -c * t * (t - 2) + b;
};

const easeInQuad = (t, b, c, d) => {
    t /= d;
    return c * t * t + b;
}

const easeInExpo = (t, b, c, d) => {
    return c * Math.pow(2, 10 * (t / d - 1)) + b;
}

export class DistortTexture {
    constructor(options) {
        this.width = options?.canvas?.getBoundingClientRect()?.width;
        this.height = options?.canvas?.getBoundingClientRect()?.height;

        this.size = this.width < 768 ? 25 : 40;
        this.points = [];
        this.radius = this.size * 0.1;
        this.maxAge = 200;
        this.last = null;

        this.initTexture(options?.canvas);
    }

    initTexture(canvas) {
        this.canvas = canvas ?? document.createElement("canvas");
        this.canvas.id = "DistortTexture";

        this.ctx = this.canvas.getContext("2d");
        this.texture = new THREE.Texture(this.canvas);

        this.clear();
    }

    clear() {
        this.ctx.fillStyle = '#f1f1f1';
        this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
    }

    addPoint(point) {
        let force = 0;
        let vx = 0;
        let vy = 0;
        const last = this.last;
        if (last) {
            const relativeX = point.x - last.x;
            const relativeY = point.y - last.y;
            // Distance formula
            const distanceSquared = relativeX * relativeX + relativeY * relativeY;
            const distance = Math.sqrt(distanceSquared);
            // Calculate Unit Vector
            vx = relativeX / distance;
            vy = relativeY / distance;

            force = Math.min(distanceSquared * 10000, 1);
        }

        this.last = {
            x: point.x,
            y: point.y,
        };

        this.points.push({x: point.x, y: point.y, age: 0, force, vx, vy});

        // if (this.updateTimeout) clearTimeout(this.updateTimeout);
    }

    update() {
        this.clear();
        let agePart = 1 / this.maxAge;
        this.points.forEach((point, i) => {
            let slowAsOlder = 1 - point.age / this.maxAge;
            let force = point.force * agePart * slowAsOlder;
            point.x += point.vx * force * 0.0175;
            point.y += point.vy * force * 0.0175;
            point.age += 1;
            if (point.age > this.maxAge) {
                this.points.splice(i, 1);
            }
        });
        this.points.forEach((point) => {
            if (!point.zDistance) {
                point.zDistance = easeInExpo(Math.random(), 0, 1, 1);
            }
            if (!point.opacity) {
                point.opacity = Math.random();
            }
            this.drawPoint(point);
        });
        this.texture.needsUpdate = true;
    }

    drawPoint(point) {
        // Convert normalized position into canvas coordinates
        let pos = {
            x: point.x * this.width,
            y: point.y * this.height,
        };

        const zDistance = point.zDistance ?? 1;
        const opacity = point.opacity ?? 1;

        const ctx = this.ctx;

        let intensity = 1;
        if (point.age < this.maxAge * 0.1) {
            intensity = easeOutSine(point.age / (this.maxAge * 0.1), 0, 1, 1);
        } else {
            intensity = easeOutQuad(
                1 - (point.age - this.maxAge * 0.1) / (this.maxAge * 0.9),
                0,
                1,
                1
            );
        }
        intensity *= point.force;

        const startIntensity = easeInQuad(Math.min(1, Math.max(0, point.age / (this.maxAge * .1))), 0, 1, 1);

        const radius = this.radius * zDistance * startIntensity;

        let offset = (1 + (intensity));
        // 1. Give the shadow a high offset.
        // ctx.shadowOffsetX = offset;
        // ctx.shadowOffsetY = offset;
        // ctx.shadowBlur = 1; //* (.1 + (intensity * .25));

        // 0 at the middle
        const xRatio = pos.x / this.width - 0.5;
        const baseColor = `${170 - 30 * (xRatio)}, ${(100)}%, 50%`;
        const color = `hsla(${baseColor}, ${
            Math.min(1, opacity * intensity * 1.5)
        })`;

        ctx.shadowColor = color;

        this.ctx.beginPath();
        this.ctx.fillStyle = color;
        // 2. Move the circle to the other direction of the offset
        this.ctx.arc(pos.x - offset, pos.y - offset, radius, 0, Math.PI * 2);
        this.ctx.fill();

        // Add a stroke
        this.ctx.strokeStyle = `hsla(${baseColor}, ${
            intensity
        })`;
        this.ctx.lineWidth = .15;
        this.ctx.stroke();
    }
}
