import Activity from "./Activities/Activity";
import Container from "../containers/Container/Container";
import Flex from "../containers/Flex/Flex";
import consultancyImage from "../../assets/images/berenger-Stasse-web-consultancy.png";
import dimImage from "../../assets/images/dim.png";
import visionlabAgencyLogo from "../../assets/images/visionlab-agency.png";
import visionlabSpaceImage from "../../assets/images/visionlab-space.jpg";
import styles from "./Activities.module.scss";

const Activities = () => {
    return (
        <Container orientation="horizontal" size="xl">
            <Flex gap={3}>
                <div className={styles.column+' '+styles['column--offset']}>
                    <Activity
                        index={2}
                        name="VisionLab"
                        description="I am the guy behind VisionLab, the dev Agency as a Service"
                        image={visionlabAgencyLogo}
                        link="https://visionlab.agency"
                    />

                    <Activity
                        index={1}
                        name="Consultancy"
                        description="I help companies in the development of their application / website"
                        image={consultancyImage}
                        link="https://www.linkedin.com/in/berengerstasse/"
                    />
                </div>

                <div className={styles.column}>
                    {/*<Activity*/}
                    {/*    index={3}*/}
                    {/*    name="Warmr"*/}
                    {/*    description="Turning LinkedIn leads into warm connexions with engagement scoring and curated lists"*/}
                    {/*    image={warmrLogo}*/}
                    {/*    link="https://www.warmr.io"*/}
                    {/*/>*/}

                    <Activity
                        index={3}
                        name="Dig It Mecanik"
                        description="Party box experience"
                        image={dimImage}
                        link="https://www.digit-mecanik.be"
                    />

                    <Activity
                        index={2}
                        name="VisionLab Space"
                        description="I'm the owner of VisionLab Space, a co-working space in the Gaume region, province of Luxembourg in Belgium."
                        image={visionlabSpaceImage}
                        link="https://space.visionlab.agency"
                    />
                </div>
            </Flex>
        </Container>
    )
}

export default Activities