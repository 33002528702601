import Container from "../components/containers/Container/Container";
import Typo from "../components/containers/Typo/Typo";
import Hero from "../components/elements/Hero";
import Animated from "../components/containers/Animated/Animated";
import Button from "../components/elements/Button";
import {useEffect} from "react";
import {useSeo} from "../hooks/seo";

const Contact = () => {

    const {setMeta} = useSeo();

    useEffect(() => {
        setMeta({
            title: "Contact — Bérenger Stasse",
            description: "I am a full-stack web and applications developer with a no-bullshit mind."
        });
    }, [setMeta]);

    return (
        <>
            <Container fullHeight={true} orientation="vertical">
                <div>
                    <Hero lines={['Get in touch']}/>

                    <Animated type="fade-in" delay={.75}>
                        <Button to="https://calendly.com/berenger-dev/30min">
                            Book a 30min call
                        </Button>
                    </Animated>

                </div>

                <a href="mailto:hello@berenger.dev">
                    <Typo align="right" variant="headline2" size="fit" fitRatio={1.75} tag="div">
                        <Animated type="slide-bottom" delay={1}>
                            hello
                        </Animated>
                        <Animated type="slide-bottom" delay={1.1}>
                            @berenger.dev
                        </Animated>
                    </Typo>
                </a>
            </Container>
        </>
    )
}

export default Contact;