const Privacy = () => {
    return (
        <>
            <h1>Politique de Confidentialité de Berenger.dev SRL</h1>

            <p><strong>Dernière mise à jour :</strong> 30 octobre 2023</p>

            <p>Berenger.dev SRL (ci-après "nous", "notre", "notre entreprise") est engagée à protéger la vie privée de
                ses utilisateurs. Cette politique de confidentialité explique comment nous collectons, utilisons et
                protégeons les informations personnelles que vous nous fournissez.</p>

            <h2>1. Informations que nous collectons</h2>
            <p>Nous pouvons collecter les informations personnelles suivantes :</p>
            <ul>
                <li>Nom</li>
                <li>Prénom</li>
                <li>Adresse e-mail</li>
                <li>Numéro de téléphone</li>
            </ul>

            <h2>2. Utilisation des informations</h2>
            <p>Nous collectons ces informations dans le but de vous contacter à des fins de prospection.</p>

            <h2>3. Partage des informations</h2>
            <p>Nous ne partageons pas vos informations personnelles avec des tiers.</p>

            <h2>4. Accès et suppression des informations</h2>
            <p>Vous pouvez demander l'accès à vos informations personnelles ou leur suppression en nous contactant par
                e-mail à l'adresse suivante : <a href="mailto:hello@berenger.dev">hello@berenger.dev</a>.</p>

            <h2>5. Contact</h2>
            <p>Pour toute question ou préoccupation concernant la confidentialité, veuillez nous contacter par e-mail à
                l'adresse suivante : <a href="mailto:hello@berenger.dev">hello@berenger.dev</a>.</p>
        </>
    );
}

export default Privacy;