import {CSSProperties, ReactNode} from "react";
import styles from "./Container.module.scss";

export interface ContainerProps {
    children: ReactNode;
    size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
    orientation?: 'horizontal' | 'vertical';
    justify?: 'start' | 'center' | 'end';
    fullHeight?: boolean;
    noSpacing?: boolean;
    className?: string;
    floating?: boolean | number;
}

const Container = ({
                       className,
                       size,
                       orientation,
                       fullHeight,
                       noSpacing,
                       floating,
                       justify,
                       children
                   }: ContainerProps) => {

    const classNames = [styles.container];
    let style_properties: CSSProperties = {};

    if (size) {
        classNames.push(styles['container--' + size]);
    }
    if (orientation) {
        classNames.push(styles['container--' + orientation]);
    }

    if (fullHeight) {
        classNames.push(styles['container--full-height']);
    }

    if (noSpacing) {
        classNames.push(styles['container--no-spacing']);
    }

    if (floating) {
        classNames.push(styles['container--floating']);
        style_properties = {
            ...style_properties,
            '--floating-offset': floating
        } as CSSProperties;
    }

    if (justify) {
        classNames.push(styles['container--justify-' + justify]);
    }

    if (className) {
        classNames.push(className);
    }

    return (
        <div className={classNames.join(' ')} style={style_properties}>
            {children}
        </div>
    );
}

export default Container;