import Flex from "../components/containers/Flex/Flex";
import Container from "../components/containers/Container/Container";
import Logo from "../components/elements/Logo";
import Link from "../components/functional/Link";

import {useScreen} from "../hooks/screen";
import MenuButton from "../components/elements/MenuButton";
import Animated from "../components/containers/Animated/Animated";

interface HeaderProps {
    menuOpen?: boolean;
    setMenuOpen?: (menuOpen: boolean) => void;
}

const Header = ({menuOpen, setMenuOpen}: HeaderProps) => {
    const {isMobile} = useScreen();

    const openMenu = (e: any) => {
        e.preventDefault();
        if (setMenuOpen)
            setMenuOpen(!menuOpen);
    }

    return (
        <Container>
            <Flex align="center" justify="between" splitWidth={true} noResponsive={true}>
                <Animated delay={1} type="slide-top">
                    <Link to="/" animate={true} hasEvents={true}>
                        <Logo withText={!isMobile}/>
                    </Link>
                </Animated>
                <Animated delay={1.15} type="slide-top">
                    <MenuButton open={menuOpen} onClick={openMenu}/>
                </Animated>
                {!isMobile &&
                    <Animated delay={1.3} type="slide-top">
                        <Link animate={true} to="contact">
                            Get in
                            touch
                        </Link>
                    </Animated>
                }
            </Flex>
        </Container>
    );
}

export default Header;