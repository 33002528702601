import {Link as RouterLink, LinkProps as RouterLinkProps, useNavigate} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import styles from './Link.module.scss';
import {useLayout} from "../../hooks/layout";

export interface LinkProps extends RouterLinkProps {
    className?: string;
    animate?: boolean;
    hasEvents?: boolean
}

const Link = ({children, to, className, animate, hasEvents, ...rest}: LinkProps) => {
    const {i18n} = useTranslation();
    const {language} = i18n;

    const navigate = useNavigate();

    const {setHasTransitionMask} = useLayout();

    if (to.toString().startsWith('http')) {
        return (
            <a
                className={className}
                href={to.toString()} {...rest} target="_blank" rel="noreferrer">
                {children}
            </a>
        )
    }

    const localized_to = (to ? '/' + language + '/' + to : '/')
        .trim()
        .replace(/\/{2,}/g, '/')
        .replace(/\/$/, '');

    const _onClick = (e: any) => {
        if (!animate) return;

        e.preventDefault();
        setHasTransitionMask(true);
        setTimeout(() => {
            navigate(e.target.href?.replace(window.location.origin, '') ?? '');
            setHasTransitionMask(false);
        }, 1000);
    }

    return (
        <>
            <RouterLink
                className={styles['link'] + (hasEvents ? ' ' + styles['link--with-events'] : '') + (className ? ' ' + className : '')}
                to={localized_to}
                onClick={_onClick}
                {...rest}
            >
                {children}
            </RouterLink>
        </>
    )
}

export default Link;