import {useState} from "react";

export const useRandomBeep = () => {
    let waveforms: OscillatorType[] = ["sine"/*, "square", "sawtooth", "triangle"*/];
    let context: AudioContext;

    const baseNotes = [
        (440 / 3), // A note
        //(261.63 / 2), // C note
        (329.63 / 2), // E note
    ];
    const harmonics: number[] = [];

    baseNotes.forEach(baseNote => {
        // Octave
        const frequency = baseNote;

        // Push octave
        harmonics.push(frequency);
        // Push 5th
        harmonics.push(frequency * 1.5);
        // Push 3rd
        harmonics.push(frequency * 1.25);
    });

    const [lastPlayedHarmonic, setLastPlayedHarmonic] = useState(0);

    function playRandomBeep() {
        if (!context) context = new AudioContext();

        let oscillatorNode = context.createOscillator();
        let gainNode = context.createGain();

        oscillatorNode.type = waveforms[Math.floor(Math.random() * waveforms.length)];

        if (!harmonics[lastPlayedHarmonic]) {
            setLastPlayedHarmonic(0);
            return;
        }
        oscillatorNode.frequency.value = harmonics[lastPlayedHarmonic];
        setLastPlayedHarmonic(lastPlayedHarmonic + 1);

        gainNode.gain.exponentialRampToValueAtTime(0.15, context.currentTime);
        gainNode.gain.exponentialRampToValueAtTime(0.000001, context.currentTime + 2);

        oscillatorNode.connect(gainNode);

        gainNode.connect(context.destination);

        oscillatorNode.start(0);

        oscillatorNode.onended = () => {
            oscillatorNode.disconnect();
            gainNode.disconnect();
        }

        setTimeout(() => {
            oscillatorNode.stop();
        }, 2000);
    }

    return {playRandomBeep};
}
