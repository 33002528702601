import styles from './Typo.module.scss';
import {createElement, CSSProperties, ReactNode} from "react";

interface TypoProps {
    children: ReactNode;
    tag?: 'p' | "div"
    size?: 'fit' | 'medium';
    weight?: 'light' | 'regular' | 'bold' | 'black';
    fitRatio?: number;
    variant?: 'headline' | 'headline2' | 'hero';
    align?: 'right' | 'left' | 'center';
    transform?: 'uppercase' | 'lowercase' | 'capitalize';
}

const Typo = ({tag, size, variant, align, fitRatio, transform, weight, children}: TypoProps) => {
    const classNames = [styles.typo];

    if (size) {
        classNames.push(styles[`typo--${size}`]);
    }

    if (variant) {
        classNames.push(styles[`typo--${variant}`]);
    }

    if (align) {
        classNames.push(styles[`typo--align-${align}`]);
    }

    if (transform) {
        classNames.push(styles[`typo--transform-${transform}`]);
    }

    if (weight) {
        classNames.push(styles[`typo--weight-${weight}`]);
    }

    let style: CSSProperties = {};

    if (fitRatio) {
        style = {
            ...style,
            '--fit-ratio': fitRatio
        } as CSSProperties;
    }

    return createElement(tag ?? 'p', {
        className: classNames.join(' '),
        style
    }, children);
}

export default Typo;