import styles from "./Logo.module.scss";
import {CSSProperties} from "react";

interface LogoProps {
    withText?: boolean;
}

const Logo = ({withText}: LogoProps) => {
    const text = [
        'Bérenger Stasse',
        'Berenger.dev'
    ];

    return (
        <div className={styles.logo}>
            <svg width="45" height="51" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill="currentColor" d="M12.845 25.5 24.62 43.608H11.774L0 25.5 11.774 7.392H24.62L12.845 25.5Z"/>
                <path fill="currentColor"
                      d="M31.772 37.058 20 18.951h8.517l3.256-5.01L22.707 0h12.845l9.066 13.942L37.102 25.5l7.516 11.558L35.551 51H22.708l9.064-13.942Z"/>
            </svg>

            {withText && <div className={styles['logo__text']}>
                {text.map((line, index) => (
                    <p className={styles['logo__text__line']} key={index}>
                        {line.split('').map((letter, index) => (
                            <span style={{
                                '--pos': index + 1
                            } as CSSProperties}
                                  className={styles['logo__text__letter']}
                                  key={index}
                            >{letter}</span>
                        ))}
                    </p>
                ))}
            </div>
            }
        </div>
    )
};

export default Logo;