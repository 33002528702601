import styles from "./Activity.module.scss";
import {useEffect, useRef, useState} from "react";

type ActivityProps = {
    name: string;
    description: string;
    image: string;
    link?: string;
    index?: number
}
const Activity = ({
                      name,
                      description,
                      image,
                      link
                  }: ActivityProps) => {
    const [isShown, setIsShown] = useState(false);
const element = useRef<any>(null);

    useEffect(() => {
        const scrollListener = () => {
            if(isShown) return;

            const elementTop = (element.current as HTMLElement)?.getBoundingClientRect().top??0;
            const viewportHeight = window.innerHeight;
            const elementHeight = viewportHeight * .85;

            if (elementTop < elementHeight) {
                setIsShown(true);
            }
        };
        window.addEventListener('scroll', scrollListener);
        return () => window.removeEventListener('scroll', scrollListener);
    }, [isShown]);

    const mainClassNames = [styles['activity']];
    if (isShown) {
        mainClassNames.push(styles['activity--visible']);
    }

    return (
        <a href={link??'#'} target="_blank" rel="noreferrer"
            ref={element}
            className={mainClassNames.join(' ')}>
            <div
                className={styles['activity__inner']}>

                <img src={image} alt={name}/>

                <h1>  {  name  }  </h1>

                <p>{description}</p>
            </div>
        </a>

    )
}

export default Activity