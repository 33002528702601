import Container from "../components/containers/Container/Container";
import Flex from "../components/containers/Flex/Flex";
import {useEffect, useState} from "react";
import Button from "../components/elements/Button";
import Typo from "../components/containers/Typo/Typo";
import Link from "../components/functional/Link";


const Footer = () => {

    const [conviced_text, setConviced_text] = useState('');

    const conviced_texts = [
        'Let\'s talk about your project.',
        'Let\'s drink a coffee.',
        'Let\'s talk about your project.',
        'Let\'s make something great together.',
    ]

    useEffect(() => {
        setConviced_text(conviced_texts[Math.floor(Math.random() * conviced_texts.length)]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const menuItems = [
        {
            label: 'About',
            link: '/',
        },
        // {
        //     label: 'Work',
        //     link: 'work',
        // },
        // {
        //     label: 'Solutions',
        //     link: 'solutions',
        // },
        {
            label: 'Contact',
            link: 'contact',
        }
    ]

    return (
        <>
            <Container size="xl">
                <Flex align="right">
                    <div>
                        <h3 className="h3">
                            Convinced?
                        </h3>
                        <Typo>
                            {conviced_text}
                        </Typo>
                        <p>&nbsp;</p>
                        <Button to={'/contact'} animate={true}>
                            Get in touch
                        </Button>
                    </div>
                    <Flex>
                        <Container>
                            &nbsp;
                        </Container>
                        <Container>
                            <Typo align="right" tag="div">
                                <nav>
                                    <ul>
                                        {menuItems.map((item, index) => (
                                            <li key={index}>
                                                <Link to={item.link} animate={true}>
                                                    {item.label}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </nav>
                            </Typo>
                        </Container>
                    </Flex>
                </Flex>
            </Container>
            <Container size="xl">
                <p>
                    &copy; 2024 berenger.dev
                </p>
            </Container>
        </>
    );
}

export default Footer;