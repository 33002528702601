import Link from "../../functional/Link";
import Container from "../../containers/Container/Container";
import styles from "../Menu.module.scss";

interface MenuItemProps {
    label: string;
    link: string;
}

const MenuItem = ({label, link}: MenuItemProps) => {

    const classNames = [styles['menu__item']];

    return (
        <Link
            className={classNames.join(' ')}
            to={link}
        >
            <Container size="xl">
                <p className={styles['menu__item-label']}>
                    {label}
                </p>
            </Container>
        </Link>
    );
}

export default MenuItem;