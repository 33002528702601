import i18n from "i18next";
import {initReactI18next} from "react-i18next";

import en from './translations/en.json';
import fr from './translations/fr.json';

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            en,
            fr
        },
        supportedLngs: ['en', 'fr'],
        fallbackLng: "en",
        interpolation: {
            escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
        }
    });

export default i18n;