import styles from "./Clients.module.scss";
import Container from "../containers/Container/Container";
import {useMemo} from "react";
import Link from "../functional/Link";

const Clients = () => {
    const titlePhrase = useMemo(() => {
        const phrases = [
            'I have made successful projects for',
            'I have brought my skills to',
            'I have brought my expertise to',
            'I have helped companies to grow',
            'They trust me to bring their vision to life',
            'They trust me to make their ideas come to life',
            'They trust me to make their products come to life',
            'They trust me to make their businesses come to life',
            'They trust my expertise to make their businesses come to life',
            'I contributed to the success of',
            'I contributed to the growth of',
            'I contributed to the development of'
        ];

        return phrases[Math.floor(Math.random() * phrases.length)];
    }, []);

    const logos = useMemo(() => {
        const logos =
            [
                require('../../assets/logos/autotuner.png'),
                require('../../assets/logos/belife.png'),
                require('../../assets/logos/biolys.png'),
                require('../../assets/logos/coppr.jpg'),
                require('../../assets/logos/digizik.png'),
                require('../../assets/logos/ferrero.png'),
                require('../../assets/logos/jost.jpg'),
                require('../../assets/logos/la-niche.png'),
                require('../../assets/logos/mediaone.png'),
                require('../../assets/logos/monfort.png'),
                require('../../assets/logos/netbee.png'),
                require('../../assets/logos/palf.png'),
                require('../../assets/logos/pastoo.png'),
                require('../../assets/logos/soilcapital.png'),
                require('../../assets/logos/springbok.png'),
                require('../../assets/logos/stib.png'),
                require('../../assets/logos/strategie.jpg'),
                require('../../assets/logos/travexploit.png'),
                require('../../assets/logos/typi-design.png'),
                require('../../assets/logos/wallimage.webp'),
                require('../../assets/logos/wanzenried.png'),
                require('../../assets/logos/warmr.png'),
                require('../../assets/logos/wot.jpg')
            ];
        // Shuffle the array
        return logos.sort(() => Math.random() - 0.5);
    }, []);

    return (
        <Container size="xl">
            <div className={styles.clients}>
                <h1>{titlePhrase}...</h1>

                <div className={styles['clients__logos']}>
                    {logos.map((logo, index) => (
                        <div key={index} className={styles['clients__logos--img']}>
                            <img src={logo} />
                        </div>
                    ))}
                    <Link className={styles['clients__logos--img']+' '+styles['clients__logos--contact']} to="/contact">
                        YOUR <br/> COMPANY?
                    </Link>
                </div>
            </div>
        </Container>
    )
}

export default Clients;