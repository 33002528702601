import {useCallback, useEffect, useState} from "react";
import {useLocation} from "react-router";

export const useScreen = () => {
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
    const [viewportMax, setViewportMax] = useState(Math.max(viewportWidth, viewportHeight));

    const [documentHeight, setDocumentHeight] = useState(document.body.scrollHeight);

    const [isMobile = viewportWidth < 768, setIsMobile] = useState(false);
    const [isTouchDevice, setIsTouchDevice] = useState(false);

    const [scrolledPercentage, setScrolledPercentage] = useState(0);
    const [scrolledPixels, setScrolledPixels] = useState(0);

    const handleWindowResize = useCallback(() => {
        setViewportWidth(window.innerWidth)
        setViewportHeight(window.innerHeight)
        setViewportMax(Math.max(window.innerWidth, window.innerHeight));
        setDocumentHeight(document.body.scrollHeight);
    }, []);

    const location = useLocation();

    useEffect(() => {
        handleWindowResize();
    },[handleWindowResize, location])

    useEffect(() => {
        handleWindowResize();

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        }
    }, [handleWindowResize]);

    useEffect(() => {
        setIsMobile(viewportWidth < 768);

        return () => setIsMobile(false);
    }, [viewportWidth]);

    useEffect(() => {
        const isTouch = (('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0));
        setIsTouchDevice(isTouch);

        return () => setIsTouchDevice(false);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const scrolled = window.scrollY;
            setScrolledPixels(scrolled);

            const totalHeight = document.body.scrollHeight - window.innerHeight;
            const percentage = scrolled / totalHeight * 100;
            setScrolledPercentage(percentage);
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const pixelRatio = window.devicePixelRatio || 1;

    return {
        viewportWidth,
        viewportHeight,
        viewportMax,
        documentHeight,
        isMobile,
        isTouchDevice,
        pixelRatio,
        scrolledPercentage,
        scrolledPixels
    };
}