import styles from './Button.module.scss';
import {CSSProperties, ReactNode} from "react";
import Link, {LinkProps} from "../functional/Link";

export interface ButtonProps extends Omit<LinkProps, 'to'> {
    to?: LinkProps['to'];
    onClick?: () => void;
    children: ReactNode | ReactNode[] | string | number;
    variant?: "fill-light";
    size?: "small";
    color?: string;
}

const Button = ({children, to, onClick, variant, size, color, ...props}: ButtonProps) => {
    const classNames = [styles['button']];
    if (variant) {
        classNames.push(styles[`button--${variant}`]);
    }
    if (size) {
        classNames.push(styles[`button--${size}`]);
    }

    const style = !color ? undefined : {
        '--color': color
    } as CSSProperties;

    if (onClick)
        return (
            <button className={classNames.join(' ')} onClick={onClick} style={style}>
                {children}
            </button>
        )

    return <Link
        to={to ?? ''}
        className={classNames.join(' ')}
        {...props}
        style={style}
    >
        {children}
    </Link>
};

export default Button;