'use client'

import {DistortTexture} from "../../webgl/DistortTexture";
import {useCallback, useEffect, useRef, useState} from "react";
import styles from "./MouseDistortEffect.module.css";
import {useLocation} from "react-router";

const MouseDistortEffect = () => {
    const canvasElement = useRef<HTMLCanvasElement>(null);
    const [canvasWidth, setCanvasWidth] = useState(0);
    const [canvasHeight, setCanvasHeight] = useState(0);

    const [waterTexture, setWaterTexture] = useState<DistortTexture | null>(null);

    const [currentPoint, setCurrentPoint] = useState<{ x: number, y: number } | null>(null);
    const isMouseTimeout = useRef<any>(null);
    const autoAnimateInterval = useRef<any>(null);

    const [showParticles, setShowParticles] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setShowParticles(true)
        }, 100)
    }, [])

    const location = useLocation();

    useEffect(() => {
        setShowParticles(false)
        setTimeout(() => {
            setShowParticles(true)
        }, 500)
    }, [location])

    useEffect(() => {
        if(!showParticles) {
            const viewportWidth = window.innerWidth;
            const documentHeight = document.body.scrollHeight;
            if (viewportWidth !== canvasWidth) setCanvasWidth(viewportWidth);
            if (documentHeight !== canvasHeight) setCanvasHeight(documentHeight);
}
            setTimeout(() => {
                if(!showParticles) {
            const viewportWidth = window.innerWidth;
            const documentHeight = document.body.scrollHeight;
                if (viewportWidth !== canvasWidth) setCanvasWidth(viewportWidth);
                if (documentHeight !== canvasHeight) setCanvasHeight(documentHeight);
                }
                setTimeout(() => {
                    if(!showParticles) {
            const viewportWidth = window.innerWidth;
            const documentHeight = document.body.scrollHeight;
                    if (viewportWidth !== canvasWidth) setCanvasWidth(viewportWidth);
                    if (documentHeight !== canvasHeight) setCanvasHeight(documentHeight);
                    }
                    setTimeout(() => {
                        if(!showParticles) {
            const viewportWidth = window.innerWidth;
            const documentHeight = document.body.scrollHeight;
                        if (viewportWidth !== canvasWidth) setCanvasWidth(viewportWidth);
                        if (documentHeight !== canvasHeight) setCanvasHeight(documentHeight);
                        }
                    }, 500)
                }, 500)
            }, 500)

    }, [showParticles,canvasElement])

    const setAutoAnimateInterval = useCallback(() => {
        if (window === undefined) return;

        clearInterval(autoAnimateInterval.current!);

        const dataParticlesAll = document.querySelectorAll('[data-particles]');
        let dataParticles = null;
        for (let i = 0; i < dataParticlesAll.length; i++) {
            const isOnViewport = dataParticlesAll[i].getBoundingClientRect().top > 0;
            if (isOnViewport) {
                dataParticles = dataParticlesAll[i];
                break;
            }
        }

        if (!dataParticles) return;

        const width = dataParticles.getBoundingClientRect().width / canvasWidth
        const height = dataParticles.getBoundingClientRect().height / canvasHeight

        const largest = height < width ? height * 2 : width;

        const topPosition = dataParticles.getBoundingClientRect().top + window.scrollY;
        const leftPosition = dataParticles.getBoundingClientRect().left;

        const centerPoint = {
            x: (leftPosition / canvasWidth) + (width / 2),
            y: (topPosition / canvasHeight) + (height / 2),
        };

        autoAnimateInterval.current = setInterval(() => {
            setCurrentPoint({
                x: ((centerPoint.x + Math.sin(Date.now() / 700) * (-width * .5) + ((Math.random() - .5) * .099))),
                y: ((centerPoint.y + Math.sin(Date.now() / 700) * (largest * .5) + ((Math.random() - .5) * .033))),
            });
        }, 15);

    }, [canvasHeight, canvasWidth]);

    useEffect(() => {
        if (!showParticles) return;

        setWaterTexture(new DistortTexture({
            canvas: canvasElement.current
        }))

        setAutoAnimateInterval();
    }, [setAutoAnimateInterval, showParticles])

    useEffect(() => {
        if (currentPoint) {
            const timeRand = Math.round(Date.now() / 1500);
            const isPair = timeRand % 2 === 0;

            const timeRand2 = Math.round(Date.now() / 100);
            const isHuge = timeRand2 % 8 === 0;

            const canvasWindowHeightRatio = window.innerHeight / canvasHeight;

            for (let i = 0; i < (isPair ? 3 : 12); i++) {
                waterTexture?.addPoint({
                    ...currentPoint,

                    x: currentPoint.x + ((Math.random() - .5) * (isHuge ? .5 : .075)),
                    y: currentPoint.y + ((Math.random() - .5) * (isHuge ? .5 : .075) * canvasWindowHeightRatio),
                });
            }
        }
    }, [currentPoint, waterTexture])

    const onMouseMove = useCallback((ev: MouseEvent) => {
        const point = {
            x: ev.clientX / canvasWidth,
            y: (ev.clientY + window.scrollY) / canvasHeight,
        };

        setCurrentPoint(point);

        clearTimeout(isMouseTimeout.current!);
        clearInterval(autoAnimateInterval.current!);
        isMouseTimeout.current = window.setTimeout(() => {
            setAutoAnimateInterval();
        }, 2500);
    }, [canvasHeight, canvasWidth, setAutoAnimateInterval]);

    const onWindowResize = useCallback(() => {
        // const hasToResize = (canvasWidth !== viewportWidth);
        // if (hasToResize) {
        //     if (viewportWidth !== canvasWidth) setCanvasWidth(viewportWidth);
        //     if (documentHeight !== canvasHeight) setCanvasHeight(documentHeight);
        //
        //     setShowParticles(false);
        //     setTimeout(() => {
        //         setShowParticles(true);
        //     }, 1000);
        // }
    }, []);

    const tick = useCallback(() => {
        waterTexture?.update();
        requestAnimationFrame(tick);
    }, [waterTexture]);

    const onTouchMove = useCallback((ev: TouchEvent) => {
        const touch = ev.touches[0];
        onMouseMove({
            clientX: touch.clientX,
            clientY: touch.clientY,
        } as MouseEvent);
    }, [onMouseMove]);

    useEffect(() => {
        if (!window) return;

        window.addEventListener("mousemove", onMouseMove);
        // on touch
        window.addEventListener("touchmove", onTouchMove);
        window.addEventListener("touchstart", onTouchMove);

        window.addEventListener("resize", onWindowResize);

        window.addEventListener("scroll", setAutoAnimateInterval);

        tick();

        return () => {
            window.removeEventListener("mousemove", onMouseMove);
            window.removeEventListener("touchmove", onTouchMove);
            window.removeEventListener("touchstart", onTouchMove);
            window.removeEventListener("resize", onWindowResize);
            window.removeEventListener("scroll", setAutoAnimateInterval);
        }
    }, [onMouseMove, onTouchMove, tick]);

    if (!showParticles) return null;

    return <div
        className={styles.scene }
        style={{
            height: canvasHeight + 'px'
        }}
    >
        <canvas
            width={canvasWidth}
            height={canvasHeight}
            ref={canvasElement}
        />
    </div>

}
export default MouseDistortEffect;
