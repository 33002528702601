export const isInViewport = (element: HTMLElement) => {
    const rect = element.getBoundingClientRect();
    const html = document.documentElement;

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || html.clientHeight) &&
        rect.right <= (window.innerWidth || html.clientWidth)
    );
}

export const positionInViewport = (element: HTMLElement, section = [0, 1], from = 'top') => {
    const rect = element.getBoundingClientRect();
    const html = document.documentElement;

    let borderTop = rect.top;
    if (from === 'center') {
        borderTop = rect.top + (rect.height / 2);
    }

    let value = borderTop / ((window.innerHeight || html.clientHeight));

    if (section[0] !== 0 || section[1] !== 1) {
        const sectionHeight = section[1] - section[0];
        value = (value - section[0]) / sectionHeight;
    }

    return (
        1 - Math.min(2, Math.max(-2, value))
    );
}