import Container from "../components/containers/Container/Container";

const E404 = () => {
    return (
        <Container size="md" orientation="horizontal">
            <div>
                <p>I'm goin' back to</p>
                <h1>404</h1>
                <p>Arctic Monkeys — 505</p>
            </div>
        </Container>
    )
}

export default E404;