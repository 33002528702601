import styles from './ScrollDown.module.scss';
import {CSSProperties, useEffect, useState} from "react";
import {useScreen} from "../../hooks/screen";

interface ScrollDownProps {
    to?: number
}

const ScrollDown = ({to}: ScrollDownProps) => {
    const [mouseDistance, setMouseDistance] = useState(0);
    const [canBeVisible, setCanBeVisible] = useState(false);

    const {viewportHeight, scrolledPercentage} = useScreen();

    const {isTouchDevice} = useScreen();

    useEffect(() => {
        setTimeout(() => {
            setCanBeVisible(true);
        }, 3000);
    }, []);

    window.addEventListener('mousemove', (e) => {
        setMouseDistance((parseInt(String(e.clientX / window.innerWidth * 20))) / 20);
    });

    const onClick = (e: any) => {
        e.preventDefault();
        window.scrollTo({
            top: to || viewportHeight,
            behavior: 'smooth'
        });
    }

    const classNames = [styles['scroll-down']];
    if (scrolledPercentage < 15 && canBeVisible) {
        classNames.push(styles['scroll-down--appear']);
    }

    return (
        <button
            className={classNames.join(' ')}
            onClick={onClick}
            style={({
                '--dist': mouseDistance
            } as CSSProperties)}
            data-particles={true}
        >
            {isTouchDevice &&
                <svg viewBox="0 0 512 512">
                    <g
                        fill="currentColor"
                    >
                        <g>
                            <g>
                                <path

                                    className={styles['scroll-down__finger']}
                                    d="M445.583,245.125l-95.688-63.792c-20.896-13.958-49.563-13.958-70.458,0l-2.104,1.406V42.667	C277.333,19.135,258.198,0,234.667,0S192,19.135,192,42.667v285.479c0,5.375-3.542,8.135-5.063,9.073	c-1.531,0.948-5.615,2.875-10.375,0.469l-68.5-34.25c-6.24-3.125-13.229-4.771-20.208-4.771	c-24.917,0-45.188,20.271-45.188,45.188V352c0,2.958,1.229,5.781,3.385,7.802l123.115,114.906	C194.938,498.75,228.542,512,263.781,512h98.885c58.813,0,106.667-47.854,106.667-106.667V289.5	C469.333,271.625,460.458,255.042,445.583,245.125z M448,405.333c0,47.052-38.281,85.333-85.333,85.333h-98.885	c-29.813,0-58.25-11.208-80.052-31.563L64,347.365v-3.51C64,330.698,74.698,320,87.854,320c3.677,0,7.375,0.875,10.667,2.521	l68.5,34.25c9.979,5,21.646,4.469,31.135-1.396c9.5-5.875,15.177-16.052,15.177-27.229V42.667	c0-11.76,9.573-21.333,21.333-21.333c11.76,0,21.333,9.573,21.333,21.333v160c0,3.938,2.167,7.552,5.635,9.406	c3.458,1.844,7.677,1.646,10.948-0.531l18.688-12.458c13.896-9.271,32.896-9.271,46.792,0l95.688,63.792	c8.927,5.948,14.25,15.906,14.25,26.625V405.333z"></path>
                                <path

                                    className={styles['scroll-down__finger-arrow']}
                                    d="M60.875,103.542l24.458-24.458v166.25C85.333,251.229,90.104,256,96,256s10.667-4.771,10.667-10.667V79.083	l24.458,24.458c2.083,2.083,4.813,3.125,7.542,3.125c2.729,0,5.458-1.042,7.542-3.125c4.167-4.167,4.167-10.917,0-15.083	l-42.667-42.667c-4.167-4.167-10.917-4.167-15.083,0L45.792,88.458c-4.167,4.167-4.167,10.917,0,15.083	C49.958,107.708,56.708,107.708,60.875,103.542z"></path>
                            </g>
                        </g>
                    </g>
                </svg>
            }
            {!isTouchDevice &&
                <svg width="177" height="177" viewBox="0 0 177 177" fill="none">
                    <path d="M89.5 54L89.5 125.412M68.491 105.306L89.3229 125.505M110.227 106.003L89.3951 126.201"
                          stroke="currentColor" className={styles['scroll-down__arrow']}/>

                    <path
                        d="M88.5 176.157C136.912 176.157 176.157 136.912 176.157 88.5C176.157 40.0883 136.912 0.842857 88.5 0.842857C40.0883 0.842857 0.842857 40.0883 0.842857 88.5C0.842857 136.912 40.0883 176.157 88.5 176.157ZM88.5 177C137.377 177 177 137.377 177 88.5C177 39.6228 137.377 0 88.5 0C39.6228 0 0 39.6228 0 88.5C0 137.377 39.6228 177 88.5 177Z"
                        className={styles['scroll-down__circle']}
                    />

                    <path
                        d="M88.5 148.343C121.55 148.343 148.343 121.55 148.343 88.5C148.343 55.4497 121.55 28.6571 88.5 28.6571C55.4497 28.6571 28.6571 55.4497 28.6571 88.5C28.6571 121.55 55.4497 148.343 88.5 148.343ZM88.5 149.186C122.016 149.186 149.186 122.016 149.186 88.5C149.186 54.9842 122.016 27.8143 88.5 27.8143C54.9842 27.8143 27.8142 54.9842 27.8142 88.5C27.8142 122.016 54.9842 149.186 88.5 149.186Z"
                        className={styles['scroll-down__circle']}
                    />

                    <g className={styles['scroll-down__text']}>
                        <path
                            d="M38.5293 42.5195C37.9322 43.2507 37.2516 43.8603 36.4874 44.3484C35.714 44.829 34.9924 45.1157 34.3225 45.2085L33.6674 43.828C34.2858 43.7236 34.9315 43.474 35.6045 43.079C36.2774 42.684 36.8639 42.1803 37.364 41.5679C37.8193 41.0104 38.1248 40.5057 38.2804 40.0539C38.4361 39.6021 38.4714 39.2044 38.3864 38.8608C38.2923 38.5097 38.1035 38.2184 37.8202 37.9871C37.4912 37.7184 37.1365 37.6116 36.7563 37.6668C36.3836 37.7128 35.996 37.8609 35.5936 38.1112C35.1895 38.3449 34.7738 38.63 34.3465 38.9667C33.9193 39.3033 33.4746 39.6334 33.0124 39.9568C32.5486 40.2636 32.0789 40.5122 31.6034 40.7028C31.1353 40.8843 30.6555 40.9495 30.1639 40.8984C29.6632 40.84 29.157 40.6017 28.6451 40.1838C28.1516 39.7807 27.8037 39.2833 27.6014 38.6916C27.3974 38.0832 27.3922 37.4086 27.5858 36.6679C27.7777 35.9105 28.217 35.1114 28.9036 34.2705C29.3589 33.713 29.8836 33.2198 30.4776 32.7908C31.0717 32.3618 31.67 32.0582 32.2726 31.88L32.9999 33.2281C32.3898 33.4154 31.8388 33.6891 31.3468 34.0491C30.8457 34.4016 30.4197 34.7926 30.0689 35.2221C29.6286 35.7614 29.3351 36.2606 29.1886 36.7199C29.0421 37.1792 29.0159 37.5844 29.11 37.9355C29.2116 38.2774 29.4041 38.5641 29.6875 38.7955C30.0256 39.0716 30.3811 39.1867 30.7538 39.1407C31.134 39.0855 31.5216 38.9374 31.9166 38.6962C32.319 38.446 32.7384 38.1562 33.1748 37.827C33.6021 37.4904 34.043 37.1649 34.4977 36.8507C34.9599 36.5273 35.425 36.2749 35.8931 36.0934C36.3686 35.9029 36.8522 35.8331 37.3437 35.8841C37.8353 35.9351 38.3324 36.1659 38.8351 36.5764C39.3195 36.9719 39.6637 37.4739 39.8677 38.0823C40.0625 38.6832 40.0602 39.3669 39.8609 40.1334C39.6598 40.8833 39.216 41.6787 38.5293 42.5195Z"
                            fill="currentColor"/>
                        <path
                            d="M46.3125 35.3623C45.5523 35.922 44.7582 36.3162 43.9301 36.5449C43.1046 36.7571 42.2864 36.8101 41.4754 36.7038C40.667 36.581 39.9024 36.3087 39.1815 35.887C38.4607 35.4652 37.8275 34.8838 37.2817 34.1426C36.736 33.4014 36.3688 32.6241 36.1801 31.8106C35.9914 30.997 35.9667 30.1873 36.106 29.3814C36.2383 28.5659 36.5318 27.8003 36.9866 27.0845C37.4438 26.3522 38.0525 25.7062 38.8127 25.1465C39.5824 24.5798 40.3882 24.1916 41.2303 23.9819C42.0748 23.7557 42.9141 23.7313 43.748 23.9086L43.4827 25.4887C42.8087 25.3694 42.1642 25.3897 41.5492 25.5495C40.9272 25.6997 40.3453 25.9743 39.8037 26.3731C39.2431 26.7858 38.7904 27.2657 38.4457 27.8125C38.1106 28.3524 37.8931 28.9228 37.7934 29.5237C37.6937 30.1247 37.719 30.7361 37.8692 31.3582C38.022 31.9637 38.3013 32.542 38.7071 33.0932C39.1129 33.6443 39.5857 34.0875 40.1255 34.4226C40.6679 34.7413 41.2408 34.9423 41.8443 35.0255C42.4477 35.1087 43.057 35.0704 43.672 34.9107C44.2965 34.7439 44.8891 34.4541 45.4497 34.0413C45.9914 33.6425 46.4298 33.1732 46.765 32.6334C47.0931 32.084 47.2969 31.4651 47.3761 30.7766L48.9637 30.9925C48.8854 31.8414 48.6164 32.6403 48.1566 33.3891C47.7064 34.1309 47.0917 34.7886 46.3125 35.3623Z"
                            fill="currentColor"/>
                        <path
                            d="M50.7311 32.4417L45.0537 21.429L49.3486 19.2148C50.3135 18.7174 51.2153 18.445 52.0538 18.3977C52.8923 18.3504 53.6439 18.5206 54.3083 18.9082C54.9728 19.2958 55.5105 19.8881 55.9215 20.6852C56.3324 21.4823 56.503 22.264 56.4333 23.0301C56.3582 23.7857 56.0582 24.4913 55.5333 25.1469C55.0083 25.8025 54.2634 26.3791 53.2985 26.8765L49.8688 28.6446L50.2037 27.556L52.3043 31.6306L50.7311 32.4417ZM58.0939 28.6459L53.2334 26.0936L54.9168 25.2258L59.8087 27.7619L58.0939 28.6459ZM50.2848 27.7133L49.1794 27.3074L52.5619 25.5636C53.5687 25.0445 54.2183 24.4375 54.5105 23.7425C54.8079 23.0317 54.7537 22.2829 54.3482 21.4963C53.9427 20.7097 53.3669 20.2365 52.6208 20.0769C51.8852 19.9118 51.0139 20.0888 50.007 20.6079L46.6246 22.3517L46.927 21.2001L50.2848 27.7133Z"
                            fill="currentColor"/>
                        <path
                            d="M65.9465 25.8303C65.0497 26.1249 64.1648 26.2478 63.2918 26.1992C62.43 26.1469 61.6305 25.9437 60.8933 25.5897C60.1523 25.2244 59.5052 24.729 58.952 24.1034C58.3987 23.4778 57.9804 22.7334 57.6968 21.8701C57.4133 21.0069 57.3089 20.1594 57.3834 19.3276C57.458 18.4958 57.6872 17.7188 58.071 16.9966C58.4511 16.2631 58.9727 15.6199 59.6356 15.0668C60.2985 14.5137 61.084 14.0881 61.9921 13.7899C62.8889 13.4953 63.7627 13.376 64.6132 13.432C65.4713 13.4731 66.2708 13.6763 67.0117 14.0415C67.7602 14.3919 68.4055 14.8817 68.9475 15.511C69.5007 16.1366 69.9209 16.8866 70.2081 17.761C70.4953 18.6355 70.6017 19.4886 70.5271 20.3204C70.4637 21.1485 70.2364 21.9311 69.845 22.6683C69.4612 23.3905 68.9379 24.0281 68.2749 24.5812C67.6195 25.1194 66.8434 25.5357 65.9465 25.8303ZM65.455 24.3336C66.1052 24.1201 66.6682 23.811 67.1439 23.4064C67.6308 22.998 68.0098 22.5258 68.2808 21.9897C68.5594 21.4386 68.7205 20.8516 68.7643 20.2287C68.8193 19.602 68.7401 18.9636 68.5265 18.3133C68.313 17.6631 67.9983 17.102 67.5824 16.63C67.1778 16.1543 66.7018 15.7828 66.1544 15.5154C65.6146 15.2332 65.0276 15.072 64.3934 15.0319C63.7705 14.9881 63.1339 15.0729 62.4836 15.2865C61.8222 15.5037 61.248 15.8165 60.7611 16.2248C60.2854 16.6295 59.9083 17.1073 59.6297 17.6583C59.3475 18.1982 59.1788 18.7814 59.1238 19.4081C59.08 20.031 59.1649 20.6676 59.3785 21.3179C59.592 21.9681 59.9011 22.531 60.3058 23.0067C60.7216 23.4787 61.205 23.854 61.7561 24.1326C62.3034 24.4 62.8886 24.5555 63.5116 24.5993C64.1457 24.6394 64.7936 24.5509 65.455 24.3336Z"
                            fill="currentColor"/>
                        <path
                            d="M74.6313 23.3371L72.6736 11.1028L74.4214 10.8231L76.1357 21.5369L82.7598 20.477L83.0031 21.9975L74.6313 23.3371Z"
                            fill="currentColor"/>
                        <path d="M85.7366 21.9143V9.52426H87.5066V20.3744H94.2149V21.9143H85.7366Z"
                              fill="currentColor"/>
                        <path
                            d="M106.617 23.537L109.854 11.5772L114.894 12.9411C116.17 13.2863 117.224 13.8405 118.056 14.6037C118.9 15.37 119.468 16.2754 119.76 17.3202C120.063 18.3679 120.055 19.4841 119.734 20.6687C119.414 21.8533 118.858 22.8215 118.068 23.5734C117.289 24.3284 116.342 24.8239 115.227 25.06C114.123 25.2992 112.933 25.2462 111.658 24.9009L106.617 23.537ZM108.728 22.5129L111.957 23.3868C112.948 23.6549 113.853 23.6982 114.673 23.5165C115.503 23.3379 116.209 22.9665 116.79 22.4023C117.373 21.8268 117.785 21.0948 118.026 20.2064C118.269 19.3065 118.282 18.4667 118.066 17.6868C117.849 16.9068 117.427 16.2303 116.8 15.6572C116.184 15.0871 115.38 14.668 114.389 14.3998L111.16 13.526L108.728 22.5129Z"
                            fill="currentColor"/>
                        <path
                            d="M124.266 29.6386C123.427 29.2061 122.719 28.6616 122.141 28.0054C121.574 27.3545 121.158 26.6422 120.893 25.8684C120.633 25.0841 120.532 24.2754 120.59 23.4423C120.648 22.6091 120.885 21.7888 121.301 20.9812C121.718 20.1736 122.249 19.5046 122.894 18.9743C123.539 18.4439 124.253 18.0622 125.037 17.8291C125.827 17.5856 126.651 17.506 127.51 17.5905C128.369 17.675 129.224 17.9363 130.073 18.3742C130.912 18.8068 131.61 19.3458 132.167 19.9913C132.74 20.6316 133.156 21.344 133.415 22.1282C133.69 22.9074 133.794 23.7109 133.726 24.5386C133.668 25.3718 133.428 26.1974 133.006 27.0155C132.585 27.8335 132.051 28.5078 131.406 29.0381C130.771 29.5738 130.054 29.9608 129.254 30.199C128.47 30.432 127.649 30.5063 126.79 30.4218C125.946 30.3323 125.105 30.0712 124.266 29.6386ZM124.988 28.2384C125.596 28.552 126.211 28.7364 126.833 28.7916C127.466 28.8522 128.068 28.791 128.641 28.6081C129.229 28.4201 129.76 28.1232 130.235 27.7174C130.72 27.3171 131.12 26.8127 131.433 26.2044C131.747 25.5961 131.926 24.9782 131.971 24.3507C132.026 23.7286 131.957 23.1288 131.763 22.5512C131.586 21.9684 131.289 21.437 130.873 20.9569C130.467 20.4822 129.96 20.088 129.351 19.7744C128.733 19.4554 128.107 19.2656 127.474 19.205C126.852 19.1498 126.247 19.2162 125.659 19.4042C125.076 19.5818 124.542 19.8707 124.057 20.2711C123.582 20.6769 123.188 21.1839 122.875 21.7922C122.561 22.4005 122.377 23.0157 122.322 23.6378C122.277 24.2653 122.348 24.8731 122.536 25.4612C122.73 26.0388 123.029 26.565 123.435 27.0397C123.852 27.5199 124.369 27.9194 124.988 28.2384Z"
                            fill="currentColor"/>
                        <path
                            d="M131.891 34.8962L136.436 22.6486L137.856 23.7921L133.648 35.0843L132.931 34.507L143.177 28.0775L144.446 29.0989L140.279 40.4244L139.59 39.8693L149.767 33.3843L151.076 34.439L140.079 41.4908L138.632 40.3251L142.47 29.8029L142.842 30.1026L133.366 36.0841L131.891 34.8962Z"
                            fill="currentColor"/>
                        <path
                            d="M141.568 43.11L152.07 36.535L152.84 37.7652L148.563 50.1121L148.159 49.467L156.785 44.0661L157.724 45.5663L147.223 52.1413L146.453 50.9111L150.73 38.5643L151.134 39.2093L142.508 44.6102L141.568 43.11Z"
                            fill="currentColor"/>
                        <path
                            d="M153.598 69.2689C153.263 68.3862 153.076 67.4919 153.035 66.5861C153.006 65.676 153.118 64.9077 153.373 64.2812L154.896 64.4041C154.677 64.9918 154.57 65.6759 154.576 66.4562C154.582 67.2365 154.725 67.9962 155.005 68.7355C155.26 69.4085 155.544 69.9254 155.858 70.2861C156.171 70.6468 156.498 70.8763 156.838 70.9745C157.189 71.0685 157.536 71.0507 157.878 70.921C158.275 70.7704 158.545 70.5167 158.687 70.1598C158.834 69.814 158.899 69.4043 158.884 68.9306C158.883 68.4638 158.844 67.9612 158.766 67.4229C158.688 66.8845 158.625 66.3344 158.576 65.7725C158.542 65.2174 158.562 64.6863 158.634 64.1792C158.711 63.6831 158.895 63.2349 159.185 62.8347C159.486 62.4304 159.945 62.111 160.563 61.8768C161.159 61.6509 161.764 61.5983 162.377 61.719C163.006 61.8465 163.593 62.1793 164.138 62.7173C164.698 63.2622 165.17 64.0422 165.555 65.0572C165.81 65.7303 165.975 66.4313 166.049 67.1602C166.124 67.8892 166.088 68.5592 165.941 69.1701L164.409 69.126C164.552 68.504 164.591 67.8899 164.525 67.2839C164.47 66.6736 164.345 66.1092 164.148 65.5906C163.901 64.9397 163.616 64.4359 163.291 64.0794C162.967 63.7229 162.629 63.4976 162.278 63.4036C161.931 63.3206 161.586 63.3439 161.244 63.4736C160.836 63.6284 160.559 63.8787 160.412 64.2245C160.27 64.5813 160.204 64.9911 160.216 65.4537C160.231 65.9274 160.272 66.4355 160.339 66.978C160.417 67.5163 160.478 68.0609 160.523 68.6118C160.572 69.1738 160.558 69.7028 160.481 70.1989C160.409 70.706 160.227 71.1596 159.937 71.5598C159.647 71.96 159.199 72.2752 158.592 72.5053C158.007 72.727 157.401 72.7741 156.772 72.6465C156.154 72.5148 155.563 72.171 154.999 71.6151C154.45 71.066 153.983 70.2839 153.598 69.2689Z"
                            fill="currentColor"/>
                        <path
                            d="M155.905 79.5879C155.8 78.6497 155.856 77.7649 156.072 76.9334C156.301 76.1124 156.664 75.3773 157.162 74.7281C157.672 74.0894 158.29 73.5634 159.016 73.15C159.742 72.7366 160.562 72.4789 161.477 72.3769C162.391 72.2749 163.248 72.3455 164.047 72.5889C164.846 72.8322 165.559 73.2157 166.188 73.7393C166.828 74.2616 167.344 74.8986 167.737 75.6503C168.142 76.4124 168.397 77.2626 168.502 78.2008C168.608 79.1507 168.541 80.0427 168.302 80.8768C168.075 81.7213 167.677 82.4603 167.106 83.0938L165.871 82.074C166.311 81.55 166.616 80.9817 166.785 80.3692C166.966 79.7554 167.019 79.1142 166.944 78.4458C166.867 77.7539 166.678 77.1219 166.377 76.55C166.077 75.9898 165.691 75.5163 165.221 75.1294C164.75 74.7426 164.208 74.4588 163.594 74.2779C162.993 74.1074 162.353 74.0601 161.673 74.136C160.993 74.2118 160.372 74.3997 159.812 74.6997C159.265 75.01 158.804 75.4057 158.431 75.8867C158.057 76.3677 157.785 76.9145 157.616 77.527C157.448 78.1512 157.403 78.8093 157.48 79.5012C157.555 80.1697 157.742 80.784 158.042 81.3442C158.354 81.9031 158.788 82.389 159.344 82.8018L158.364 84.0689C157.668 83.5766 157.11 82.9441 156.692 82.1716C156.274 81.4108 156.012 80.5495 155.905 79.5879Z"
                            fill="currentColor"/>
                        <path
                            d="M156.225 84.8748L168.601 85.4644L168.371 90.291C168.319 91.3754 168.104 92.2925 167.726 93.0423C167.348 93.7922 166.825 94.3579 166.157 94.7396C165.489 95.1212 164.707 95.2907 163.811 95.248C162.915 95.2054 162.153 94.9623 161.525 94.5189C160.908 94.0761 160.447 93.4634 160.141 92.681C159.836 91.8986 159.709 90.9652 159.761 89.8808L159.944 86.0266L160.72 86.8609L156.141 86.6428L156.225 84.8748ZM155.831 93.149L160.471 90.2159L160.381 92.1077L155.739 95.0762L155.831 93.149ZM160.543 86.8525L161.447 86.0982L161.266 89.8994C161.212 91.0309 161.413 91.8969 161.869 92.4975C162.336 93.1104 163.011 93.4379 163.895 93.48C164.779 93.5222 165.477 93.2601 165.988 92.6937C166.499 92.1392 166.781 91.2962 166.835 90.1647L167.016 86.3635L167.862 87.2012L160.543 86.8525Z"
                            fill="currentColor"/>
                        <path
                            d="M154.343 101.357C154.536 100.434 154.872 99.6057 155.351 98.874C155.827 98.1538 156.403 97.563 157.078 97.1015C157.765 96.6425 158.517 96.3298 159.336 96.1635C160.154 95.9971 161.008 96.007 161.897 96.1931C162.787 96.3792 163.573 96.7125 164.256 97.193C164.939 97.6735 165.497 98.2605 165.931 98.9539C166.376 99.6499 166.672 100.423 166.82 101.274C166.967 102.124 166.943 103.018 166.747 103.953C166.554 104.877 166.22 105.693 165.747 106.402C165.282 107.124 164.706 107.715 164.02 108.174C163.342 108.647 162.595 108.961 161.779 109.116C160.961 109.282 160.101 109.271 159.2 109.083C158.299 108.894 157.507 108.56 156.824 108.079C156.139 107.61 155.575 107.022 155.132 106.315C154.698 105.621 154.408 104.849 154.26 103.998C154.122 103.162 154.149 102.281 154.343 101.357ZM155.885 101.68C155.745 102.35 155.731 102.992 155.843 103.606C155.953 104.232 156.173 104.797 156.502 105.299C156.84 105.816 157.267 106.249 157.785 106.599C158.3 106.96 158.893 107.21 159.563 107.35C160.233 107.49 160.876 107.498 161.493 107.374C162.107 107.262 162.667 107.035 163.172 106.695C163.686 106.369 164.119 105.941 164.471 105.412C164.82 104.894 165.065 104.3 165.205 103.63C165.348 102.949 165.364 102.295 165.254 101.67C165.142 101.055 164.916 100.49 164.578 99.9729C164.252 99.4586 163.831 99.0209 163.316 98.66C162.798 98.3106 162.205 98.0658 161.535 97.9256C160.865 97.7854 160.223 97.7716 159.608 97.8842C158.992 98.0083 158.425 98.2394 157.908 98.5773C157.403 98.9176 156.976 99.3466 156.626 99.8642C156.275 100.393 156.027 100.999 155.885 101.68Z"
                            fill="currentColor"/>
                        <path
                            d="M152.16 110.125L163.734 114.547L163.102 116.201L152.966 112.328L150.572 118.595L149.134 118.045L152.16 110.125Z"
                            fill="currentColor"/>
                        <path
                            d="M147.839 120.454L158.569 126.649L157.684 128.182L148.288 122.757L144.934 128.567L143.6 127.797L147.839 120.454Z"
                            fill="currentColor"/>
                        <path
                            d="M135.993 137.726L144.733 146.509L141.031 150.192C140.095 151.124 139.088 151.76 138.01 152.099C136.925 152.447 135.857 152.486 134.806 152.217C133.747 151.955 132.785 151.39 131.919 150.52C131.053 149.65 130.493 148.685 130.237 147.624C129.972 146.572 130.017 145.504 130.37 144.421C130.714 143.345 131.355 142.341 132.292 141.409L135.993 137.726ZM135.825 140.066L133.454 142.426C132.726 143.15 132.236 143.912 131.984 144.713C131.723 145.521 131.692 146.318 131.89 147.103C132.097 147.896 132.525 148.619 133.174 149.271C133.831 149.932 134.552 150.364 135.336 150.566C136.12 150.768 136.917 150.741 137.727 150.484C138.528 150.236 139.293 149.75 140.021 149.025L142.392 146.666L135.825 140.066Z"
                            fill="currentColor"/>
                        <path
                            d="M121.885 149.96C122.679 149.449 123.505 149.108 124.362 148.936C125.209 148.77 126.034 148.766 126.837 148.923C127.646 149.091 128.396 149.408 129.089 149.874C129.782 150.341 130.374 150.956 130.865 151.721C131.356 152.485 131.67 153.279 131.807 154.103C131.943 154.927 131.917 155.737 131.727 156.532C131.543 157.338 131.2 158.091 130.697 158.793C130.194 159.495 129.541 160.104 128.737 160.621C127.942 161.131 127.127 161.466 126.289 161.626C125.448 161.801 124.624 161.805 123.815 161.638C123.002 161.487 122.255 161.175 121.572 160.702C120.879 160.235 120.284 159.615 119.787 158.84C119.289 158.066 118.972 157.267 118.835 156.443C118.688 155.626 118.712 154.811 118.906 153.999C119.096 153.204 119.442 152.455 119.945 151.753C120.444 151.068 121.091 150.47 121.885 149.96ZM122.737 151.285C122.161 151.655 121.694 152.095 121.335 152.606C120.966 153.124 120.718 153.676 120.59 154.263C120.459 154.867 120.45 155.475 120.564 156.089C120.668 156.71 120.906 157.308 121.276 157.883C121.646 158.459 122.091 158.923 122.612 159.276C123.123 159.635 123.677 159.875 124.274 159.996C124.868 160.134 125.476 160.142 126.1 160.022C126.714 159.908 127.309 159.666 127.885 159.296C128.471 158.919 128.948 158.472 129.317 157.955C129.675 157.444 129.92 156.886 130.052 156.283C130.189 155.69 130.206 155.083 130.102 154.462C129.988 153.848 129.746 153.254 129.376 152.678C129.006 152.102 128.565 151.635 128.054 151.276C127.533 150.923 126.971 150.682 126.367 150.55C125.771 150.429 125.165 150.425 124.551 150.539C123.927 150.66 123.322 150.908 122.737 151.285Z"
                            fill="currentColor"/>
                        <path
                            d="M113.519 153.934L121.853 163.994L120.153 164.652L112.478 155.362L113.336 155.03L113.781 167.118L112.263 167.705L104.538 158.434L105.363 158.115L105.891 170.171L104.323 170.778L103.714 157.728L105.447 157.057L112.641 165.642L112.195 165.815L111.753 154.617L113.519 153.934Z"
                            fill="currentColor"/>
                        <path
                            d="M101.567 158.208L102.011 170.59L100.56 170.642L92.0061 160.764L92.7667 160.737L93.1309 170.908L91.362 170.971L90.9187 158.589L92.3691 158.537L100.923 168.415L100.163 168.442L99.7984 158.271L101.567 158.208Z"
                            fill="currentColor"/>
                        <path
                            d="M74.5477 157.186C75.4794 157.338 76.3477 157.623 77.1525 158.04C77.9554 158.47 78.5645 158.951 78.9798 159.485L78.1118 160.743C77.7122 160.259 77.1731 159.825 76.4946 159.44C75.816 159.054 75.0866 158.798 74.3062 158.671C73.5957 158.556 73.006 158.544 72.5369 158.635C72.0678 158.726 71.7057 158.894 71.4506 159.14C71.1936 159.397 71.0357 159.706 70.977 160.067C70.9088 160.486 70.9937 160.847 71.2315 161.148C71.4577 161.448 71.7798 161.71 72.1978 161.933C72.6022 162.166 73.057 162.384 73.5622 162.585C74.0674 162.787 74.5755 163.007 75.0867 163.246C75.5843 163.494 76.0345 163.776 76.4373 164.093C76.8285 164.408 77.1249 164.79 77.3265 165.242C77.5262 165.704 77.573 166.262 77.4669 166.914C77.3647 167.543 77.1079 168.093 76.6965 168.564C76.2716 169.045 75.69 169.387 74.9517 169.59C74.1999 169.802 73.2882 169.821 72.2167 169.647C71.5062 169.532 70.8167 169.324 70.1482 169.024C69.4797 168.724 68.9176 168.357 68.4619 167.925L69.2657 166.621C69.733 167.055 70.2455 167.396 70.8033 167.642C71.3591 167.9 71.9107 168.073 72.4581 168.162C73.1453 168.274 73.7244 168.278 74.1954 168.176C74.6664 168.073 75.0304 167.893 75.2874 167.636C75.5327 167.377 75.6848 167.067 75.7435 166.706C75.8135 166.275 75.7355 165.909 75.5092 165.61C75.2714 165.308 74.9493 165.046 74.543 164.825C74.125 164.602 73.6644 164.383 73.1611 164.17C72.6559 163.968 72.1536 163.749 71.6541 163.512C71.1429 163.274 70.6918 162.997 70.3006 162.683C69.8978 162.366 69.5956 161.982 69.394 161.531C69.1924 161.08 69.1437 160.534 69.2478 159.893C69.3482 159.276 69.6108 158.727 70.0357 158.246C70.4587 157.777 71.052 157.437 71.8155 157.226C72.5654 157.025 73.4762 157.012 74.5477 157.186Z"
                            fill="currentColor"/>
                        <path
                            d="M64.4578 154.025C65.3226 154.403 66.061 154.894 66.6731 155.496C67.2696 156.105 67.7246 156.787 68.038 157.543C68.3359 158.304 68.4824 159.103 68.4776 159.938C68.4728 160.773 68.2858 161.612 67.9168 162.455C67.5478 163.299 67.0582 164.005 66.448 164.575C65.8378 165.146 65.1489 165.572 64.3813 165.854C63.609 166.147 62.7992 166.276 61.9519 166.24C61.0891 166.21 60.2253 166.006 59.3605 165.628C58.4849 165.244 57.7458 164.741 57.1431 164.116C56.525 163.498 56.0842 162.783 55.8208 161.972L57.3218 161.412C57.5555 162.055 57.8953 162.603 58.3412 163.056C58.7824 163.52 59.311 163.886 59.9272 164.156C60.565 164.435 61.2069 164.587 61.8528 164.612C62.4879 164.633 63.0906 164.536 63.6609 164.322C64.2312 164.108 64.7481 163.78 65.2117 163.339C65.6597 162.904 66.0209 162.373 66.2953 161.746C66.5697 161.119 66.7171 160.488 66.7374 159.853C66.7422 159.223 66.6298 158.627 66.4002 158.063C66.1705 157.498 65.8327 156.99 65.3868 156.537C64.9302 156.08 64.3829 155.711 63.7451 155.432C63.1289 155.163 62.5033 155.018 61.8682 154.997C61.2283 154.988 60.5905 155.121 59.9546 155.396L59.3477 153.914C60.1221 153.557 60.9484 153.39 61.8268 153.414C62.6944 153.433 63.5714 153.637 64.4578 154.025Z"
                            fill="currentColor"/>
                        <path
                            d="M59.7191 151.658L53.0206 162.081L48.9556 159.469C48.0423 158.882 47.3555 158.237 46.8953 157.535C46.4351 156.832 46.2067 156.096 46.2101 155.327C46.2136 154.558 46.4577 153.796 46.9426 153.042C47.4274 152.287 48.019 151.749 48.7173 151.426C49.4092 151.113 50.1703 151.02 51.0006 151.147C51.8308 151.274 52.7026 151.631 53.6158 152.217L56.8619 154.304L55.7516 154.558L58.2301 150.701L59.7191 151.658ZM52.7505 147.18L52.9704 152.665L51.3771 151.641L51.1275 146.137L52.7505 147.18ZM55.8473 154.409L56.0485 155.569L52.8471 153.512C51.8941 152.899 51.0437 152.64 50.2957 152.735C49.5314 152.833 48.91 153.254 48.4316 153.998C47.9531 154.743 47.8313 155.478 48.066 156.204C48.2909 156.924 48.8798 157.59 49.8328 158.202L53.0342 160.26L51.8856 160.574L55.8473 154.409Z"
                            fill="currentColor"/>
                        <path
                            d="M46.3857 141.787C47.0893 142.416 47.6382 143.121 48.0326 143.902C48.4182 144.674 48.642 145.468 48.704 146.284C48.7582 147.108 48.6527 147.916 48.3875 148.708C48.1223 149.5 47.6868 150.234 47.081 150.912C46.4752 151.589 45.7934 152.103 45.0358 152.454C44.2782 152.806 43.4907 152.996 42.6733 153.024C41.8481 153.062 41.0302 152.932 40.2198 152.634C39.4094 152.337 38.648 151.869 37.9357 151.232C37.2321 150.602 36.692 149.905 36.3152 149.141C35.9218 148.377 35.698 147.583 35.6438 146.759C35.5729 145.936 35.6745 145.132 35.9485 144.348C36.2136 143.556 36.6531 142.817 37.2668 142.131C37.8805 141.445 38.5661 140.926 39.3238 140.575C40.0726 140.216 40.8641 140.022 41.6981 139.992C42.5155 139.963 43.3294 140.098 44.1398 140.395C44.9336 140.694 45.6822 141.158 46.3857 141.787ZM45.3354 142.961C44.8253 142.505 44.2762 142.172 43.6879 141.962C43.0908 141.745 42.4924 141.652 41.8926 141.686C41.2761 141.72 40.6871 141.874 40.1257 142.148C39.5555 142.413 39.0422 142.801 38.5859 143.311C38.1296 143.821 37.801 144.374 37.6001 144.97C37.3905 145.559 37.3067 146.157 37.3489 146.764C37.3743 147.373 37.5282 147.962 37.8106 148.531C38.0842 149.093 38.476 149.602 38.986 150.058C39.5049 150.522 40.0628 150.863 40.6599 151.08C41.2482 151.29 41.8505 151.378 42.467 151.344C43.0757 151.318 43.6651 151.172 44.2353 150.907C44.7967 150.633 45.3056 150.241 45.7619 149.731C46.2182 149.221 46.5512 148.672 46.7609 148.084C46.9617 147.488 47.045 146.881 47.0107 146.265C46.9686 145.657 46.8107 145.073 46.5372 144.511C46.2548 143.942 45.8542 143.425 45.3354 142.961Z"
                            fill="currentColor"/>
                        <path
                            d="M39.8842 135.512L30.2678 143.325L29.1517 141.951L37.573 135.11L33.343 129.903L34.5382 128.932L39.8842 135.512Z"
                            fill="currentColor"/>
                        <path
                            d="M33.0993 126.606L22.3693 132.801L21.4843 131.269L30.8807 125.843L27.5266 120.034L28.8602 119.264L33.0993 126.606Z"
                            fill="currentColor"/>
                        <path
                            d="M24.0643 107.712L12.0885 110.889L10.7496 105.842C10.4107 104.564 10.3636 103.374 10.6083 102.272C10.8499 101.158 11.3501 100.213 12.1089 99.4382C12.8647 98.6517 13.8357 98.1011 15.0219 97.7864C16.2081 97.4717 17.3243 97.4686 18.3705 97.777C19.4138 98.074 20.3164 98.6464 21.0785 99.4942C21.8375 100.33 22.3865 101.387 22.7254 102.665L24.0643 107.712ZM22.122 106.396L21.2642 103.162C21.0009 102.17 20.5858 101.365 20.0188 100.746C19.4488 100.116 18.7744 99.6903 17.9955 99.4697C17.2053 99.252 16.3654 99.2612 15.4758 99.4972C14.5747 99.7363 13.8407 100.145 13.2737 100.722C12.7067 101.3 12.3318 102.004 12.149 102.834C11.9633 103.652 12.002 104.557 12.2653 105.55L13.1231 108.783L22.122 106.396Z"
                            fill="currentColor"/>
                        <path
                            d="M20.5241 89.3767C20.569 90.3196 20.4517 91.2053 20.1722 92.0338C19.8922 92.8504 19.4833 93.5669 18.9456 94.1832C18.3961 94.8 17.7462 95.2917 16.9958 95.6582C16.2454 96.0247 15.4164 96.2296 14.5088 96.2728C13.6012 96.3161 12.7565 96.1909 11.9747 95.8974C11.1928 95.6039 10.505 95.1759 9.91116 94.6135C9.30554 94.0517 8.82452 93.3776 8.4681 92.5913C8.11168 91.805 7.91073 90.9344 7.86525 89.9797C7.82034 89.0368 7.93819 88.1629 8.21881 87.358C8.48708 86.5419 8.89595 85.8255 9.44543 85.2086C9.98255 84.5806 10.6266 84.0892 11.3776 83.7344C12.128 83.3679 12.9629 83.1627 13.8822 83.1189C14.8016 83.0752 15.6522 83.2 16.434 83.4936C17.2153 83.7753 17.9091 84.203 18.5152 84.7766C19.1091 85.339 19.5842 86.0133 19.9406 86.7996C20.2847 87.5748 20.4792 88.4338 20.5241 89.3767ZM18.9506 89.4517C18.918 88.768 18.7701 88.1431 18.5069 87.5768C18.2431 86.9986 17.8889 86.5075 17.4444 86.1034C16.9875 85.6881 16.4647 85.3763 15.8759 85.1681C15.2865 84.9481 14.6501 84.8544 13.9664 84.8869C13.2828 84.9195 12.6581 85.0733 12.0924 85.3483C11.5261 85.6116 11.0411 85.9714 10.6376 86.4277C10.2217 86.8728 9.90991 87.3956 9.70225 87.9962C9.49403 88.5849 9.4062 89.2211 9.43877 89.9048C9.4719 90.6002 9.62036 91.2369 9.88417 91.815C10.1474 92.3814 10.5075 92.8722 10.9644 93.2875C11.4094 93.7034 11.9267 94.0213 12.516 94.2414C13.1048 94.4496 13.741 94.5374 14.4246 94.5048C15.1082 94.4723 15.7332 94.3244 16.2995 94.0611C16.8653 93.7861 17.3558 93.4201 17.7711 92.9633C18.1747 92.5069 18.4806 91.9844 18.6888 91.3956C18.8964 90.7951 18.9837 90.1471 18.9506 89.4517Z"
                            fill="currentColor"/>
                        <path
                            d="M21.265 80.1448L8.3854 82.3319L8.66576 80.5305L20.5492 78.5287L20.4076 79.4381L9.71646 73.7796L9.96688 72.1706L21.8585 70.1163L21.7224 70.9907L11.0176 65.4196L11.2762 63.7581L22.8818 69.7561L22.596 71.5925L11.5645 73.5298L11.638 73.0576L21.5562 78.2734L21.265 80.1448Z"
                            fill="currentColor"/>
                        <path
                            d="M23.5395 67.6568L12.5947 61.8497L13.2749 60.5676L26.1063 58.0985L25.7496 58.7708L16.7592 54.0007L17.5887 52.4371L28.5336 58.2442L27.8534 59.5263L15.022 61.9955L15.3787 61.3232L24.3691 66.0932L23.5395 67.6568Z"
                            fill="currentColor"/>
                    </g>
                </svg>
            }
        </button>
    )
}

export default ScrollDown;