import {create} from 'zustand'

const useLayoutStore = create((set) => ({
    menuOpen: false,
    setMenuOpen: (menuOpen: boolean) => set({menuOpen}),

    hasOverlay: false,
    setHasOverlay: (hasOverlay: boolean) => set({hasOverlay}),

    hasTransitionMask: false,
    setHasTransitionMask: (hasTransitionMask: boolean) => set({hasTransitionMask}),

    hasOffsetTop: true,
    setHasOffsetTop: (hasOffsetTop: boolean) => set({hasOffsetTop}),
}))

export default useLayoutStore