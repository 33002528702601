import styles from './Animated.module.scss';
import {createElement, CSSProperties, ReactNode, useEffect, useRef, useState} from "react";
import {isInViewport, positionInViewport} from "../../../helpers/dom";
import {useScreen} from "../../../hooks/screen";

interface AnimatedProps {
    children: ReactNode;
    type?: 'slide-top' | 'slide-bottom' | 'opacity' | 'fade-in' | 'weight-up';
    delay?: number;
    onVisible?: boolean;
    tag?: 'div' | 'span' | 'p';
}

const Animated = ({tag, type, delay, children, onVisible, ...rest}: AnimatedProps) => {
    const element = useRef<HTMLDivElement>(null);
    const [appeared, setAppeared] = useState(false);
    const [classNames, setClassNames] = useState([styles.animated]);
    const {scrolledPercentage} = useScreen();
    const [style, setStyle] = useState({} as CSSProperties);

    useEffect(() => {
        const classNames = [styles.animated];

        if (!onVisible) {
            setAppeared(true);
        }

        if (appeared) {
            classNames.push(styles['animated--appear']);
        }

        setClassNames(classNames);
    }, [appeared, onVisible]);

    useEffect(() => {
        if (delay) {
            setStyle({
                ...style,
                '--delay': delay + 's'
            } as CSSProperties)
        }
    }, []);

    if (!appeared) {
        window.addEventListener('scroll', () => {
            if (!appeared && element.current && isInViewport(element.current)) {
                setAppeared(true);
            }
        });
    }

    const hasMask = type === 'opacity';

    useEffect(() => {
        if (hasMask && element.current) {
            setStyle({
                ...style,
                '--prog': positionInViewport(element.current, [.2, .8])
            } as CSSProperties);

        }
    }, [scrolledPercentage]);

    const className = [...classNames, styles['animated--' + type]].join(' ');

    if (!hasMask)
        return createElement(
            tag || 'div',
            {
                ref: element,
                className: className,
                style: style,
                ...rest
            },
            children
        );

    return (
        <div ref={element} className={className} style={style} {...rest}>
            <div className={styles['animated__original']} data-original={""}>
                {children}
            </div>

            <div className={styles['animated__mask']} data-mask={""}>
                {children}
            </div>
        </div>
    )
}

export default Animated;