import styles from './Pointer.module.scss';
import {useScreen} from "../../hooks/screen";
import {CSSProperties, useState} from "react";

const Pointer = () => {
    const {isTouchDevice, viewportWidth, viewportHeight} = useScreen();
    const [mousePosition, setMousePosition] = useState({x: 0, y: 0});
    const [mouseMoving, setMouseMoving] = useState(false);
    const [hovering, setHovering] = useState(false);
    const [hoveringColor, setHoveringColor] = useState('' as string);

    if (isTouchDevice) return (<></>);

    let mouseMovingTimeout: any = null;
    const onMouseMove = (e: any) => {
        setMousePosition({x: e.clientX, y: e.clientY});
        setMouseMoving(true);

        clearTimeout(mouseMovingTimeout);
        mouseMovingTimeout = setTimeout(() => {
            setMouseMoving(false);
            setHovering(false);
        }, 1000);

        const elements = document.elementsFromPoint(e.clientX, e.clientY);

        const clickableElements = elements.filter((element: any) => {
            return element.tagName === 'A' || element.tagName === 'BUTTON';
        });

        setHovering(clickableElements.length > 0);

        const elementWithScheme = elements.find((element: any) => {
            return element.dataset.scheme;
        });

        if (elementWithScheme) {
            setHoveringColor((elementWithScheme as HTMLElement).dataset.scheme ?? '');
        } else {
            setHoveringColor('');
        }
    }

    const onScroll = (e: any) => {
        setMouseMoving(false);
        setHovering(false);
    }


    const style = {
        '--x': mousePosition.x + 'px',
        '--y': mousePosition.y + 'px',
    } as CSSProperties;

    const isOnBorder = mousePosition.x === 0 || mousePosition.y === 0 || mousePosition.x === viewportWidth || mousePosition.y === viewportHeight;

    const classNames = [styles.pointer];
    if (!mouseMoving || isOnBorder) {
        classNames.push(styles['pointer--hidden']);
    }

    if (hovering) {
        classNames.push(styles['pointer--hovering']);
    }

    if (hoveringColor) {
        classNames.push(styles[`pointer--${hoveringColor}`]);
    }

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('scroll', onScroll);

    return (
        <div className={classNames.join(' ')} style={style}></div>
    )
}

export default Pointer;