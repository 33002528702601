import {ReactNode} from "react";
import styles from "./Flex.module.scss";

interface GridProps {
    children: ReactNode;
    align?: "left" | "center" | "right";
    justify?: "left" | "center" | "right" | "between";
    splitWidth?: boolean;
    noResponsive?: boolean;
    gap?: number;
}

const Flex = ({children, align,gap, justify, splitWidth, noResponsive}: GridProps) => {
    const classNames = [styles.grid];

    if (align) {
        classNames.push(styles['grid--align-' + align]);
    }

    if(gap){
        classNames.push(styles['grid--gap-'+gap]);
    }

    if (justify) {
        classNames.push(styles['grid--justify-' + justify]);
    }

    if (splitWidth) {
        classNames.push(styles['grid--split-width']);
    }

    if (noResponsive) {
        classNames.push(styles['grid--no-responsive']);
    }

    return (
        <div className={classNames.join(' ')}>
            {children}
        </div>
    );
}

export default Flex;